.DataViz {
	position: relative;
	width: 100%;
	/*max-width: 500px;*/
	height: 100%;
	/*margin: 0 auto;*/
}

.DataViz-Frame {
	position: absolute;
	z-index: 0;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
}
