html {
  font-size: 16px;
  overflow: hidden;
}
body {
  font-size: 1rem;
}
@media screen and (min-height: 1070px) {
  html {
    font-size: 19px;
  }
}
@media screen and (min-height: 1200px) {
  html {
    font-size: 20px;
  }
}
@media screen and (min-height: 1300px) {
  html {
    font-size: 24px;
  }
}
@media screen and (min-height: 1600px) {
  html {
    font-size: 30px;
  }
}
.App {
}

.App-logo {
  pointer-events: none;
  max-height: 100%;
}

.App-header {
  background-color: black;
  height: 6rem;
  position: relative;
}
.App-header h1 {
  /*text-align: center;*/
  margin: 0 1rem;
  font-size: 2rem;
  color: white;
}
.App-header .KitLogo {
  position: absolute;
  right: 0.5rem;
  top: 1px;
  bottom: 1px;
  filter: invert(1);
}
.App-header .KitLogo img {
  height: 100%;
}
.App-header .Timestamp {
  color: #888888;
  font-size: 2rem;
  position: absolute;
  /*top: 1px;*/
  bottom: 0.5rem;
  left: 1rem;
  /*transform: translateX(-50%);*/
}
.App-Container {
  position: absolute;
  top: 6rem;
  bottom: 0px;
  left: 0;
  right: 0;
}

.App-Container .Ranking {
  position: absolute;
  left: 1%;
  top: 0;
  bottom: 0;
  width: 33%;
  max-width: 38rem;
  z-index: 100;
}

.App-Container .App-LogoCentral {
  position: absolute;
  z-index: 50;
  height: 60%;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}
.App-Container .DataViz {
  position: absolute;
  left: 00px;
  right: 10%;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.App-Container .Chat {
  position: absolute;
  /*right: 1%;*/
  right: 0;
  padding-right: 1%;
  top: 0;
  bottom: 0;
  width: 33%;
}

@media screen and (min-width: 2200px) {
  .App-Container .Chat {
    width: 40%;
  }
}
@media screen and (max-width: 1280px) {
  .App-header {
    height: 70px;
  }
  .App-Container {
    top: 70px;
  }
  .App-header h1,
  .App-header .Timestamp {
    font-size: 1.5rem;
    padding-right: 100px;
  }
}
@media screen and (max-width: 480px) {
  .App-header h1,
  .App-header .Timestamp {
    font-size: 1.5rem;
    padding-right: 100px;
  }
  .App-header .KitLogo img {
    max-width: 100px;
    height: auto;
  }
  .App-Container .Ranking {
    position: relative;
    left: 0;
    width: auto;
  }
  .App-Container .App-LogoCentral {
    display: none;
  }
  .App-Container .Chat {
    position: relative;
    width: auto;
    padding: 0 2%;
  }
}
