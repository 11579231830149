.Chat {
	max-height: 100%;
	overflow-y: scroll;
	overflow-y: -webkit-scroll;
}
.Chat-Message {
	position: relative;
	/*border: 0.0625rem solid black;*/
	border: 0.125rem solid black;
	border-radius: 1rem;
	padding: 0.5rem;
	margin: 0.2rem;
	width: 75%;
	float: left;
	opacity: 1;
}
#Chat-Log-End {
	clear: both;
	padding-top: 5rem;
}
.Chat-Message.-FromMe {
	float: right;
	opacity: 1;
}
.Chat-Message.-ToMe {
	opacity: 1;
}
.Chat-Message p {
	margin: 0;
	letter-spacing: 1px;
	line-height: 1.3;
	font-size: 1.1rem;
}
.Chat-Message header {
	font-size: 0.8rem;
	margin-top: -0.5rem;
}
.Chat-Message-Metric {
	font-family: "Ubuntu Mono", "Courier";
}
.Chat-Message-From {
	/*position: absolute;*/
	/*left: 0;*/
	float: right;
	/*width: 2rem;*/
	/*height: 2rem;*/
	/*background-color: white;*/
	/*border-radius: 50%;*/
	/*overflow: hidden;*/
}
.Chat-Message-From .KitLogo {
	position: relative;
	height: 100%;
	display: none;
}
.Chat-Message-From img {
	max-height: 100%;
	max-width: 100%;
}
.Chat-Message-To {
	margin-right: 0.2rem;
	font-style: italic;
	opacity: 0.8;
}
.Chat-Message.-ToMe .Chat-Message-To {
	font-weight: 700;
	opacity: 1;
}
.Chat-Message .joypixels {
	max-height: 1rem;
}
