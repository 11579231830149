.Ranking {
	display: flex;
	flex-direction: column;
}
.Ranking-Card {
	border-width: 0.125rem;
	border-style: solid;
	border-radius: 0.4rem;
	margin: 1rem;
	position: relative;
	flex-grow: 1;
}
.Ranking header {
	display: flex;
	align-items: center;
}
.Ranking header .Ranking-Rank {
	text-align: center;
	color: white;
	width: 1.5em;
	height: 1.5em;
	flex-shrink: 0;
	border-radius: 50%;
	line-height: 1.5;
	font-weight: 700;
	font-size: 2em;
	margin-left: -0.8rem;
	margin-top: -0.8rem;
}
.Ranking header h3 {
	flex-grow: 1;
	margin: 0 0.5rem;
}
.Ranking header h3 .Kit-Location {
	display: block;
	font-size: 0.8rem;
	font-weight: normal;
}
.Ranking header img {
	max-height: 2.5rem;
	margin: 0.2rem;
}
.Ranking table {
	font-size: 0.8rem;
	margin: 0;
	font-family: "Ubuntu Mono", "Courier";
	float: left;
	margin: 0 5%;
}
.Ranking table th,
.Ranking table td {
	text-align: right;
	padding: 0;
}
.Ranking table.Ranking-ChemData {
	/*position: absolute;*/
	right: 0;
	bottom: 0;
	float: right;
}
.Ranking table.Ranking-ChemData th {
	text-align: left;
}
.Ranking .Ranking-SensorTimestamp {
	opacity: 0.5;
}
.Ranking-Card svg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.Ranking-Card svg path {
	fill-opacity: 0.3;
	stroke: #00000088;
	stroke-width: 3;
	stroke-linejoin: round;
	stroke-linecap: round;
}
.Ranking-Card svg circle {
	fill: none;
	stroke: #00000088;
	stroke-dasharray: 2 2;
	stroke-width: 1;
}
@media screen and (max-width: 1280px) {
	.Ranking-Card {
		margin: 0.5rem;
	}
	.Ranking table {
		font-size: 0.75rem;
	}
}
